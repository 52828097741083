
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
export default {
  name: 'StartTour',

  components: {
    PDEButton,
    PDEHeading,
  },

  methods: {
    startTour() {
      const intro = this.$introJs()

      intro.setOptions({
        steps: [
          {
            element: '[data-test="provider-dashboard-layout"]',
            intro: 'New Dashboard layout',
          },
          {
            element: '[data-test="provider-dashboard-reminders"]',
            intro: 'Reminders section',
          },
          {
            element: '[data-test="provider-dashboard-what-to-expect"]',
            intro: 'What to expect section',
          },
        ],
      })
      intro.start()
    },
  },
}
