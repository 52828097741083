
import PDEBadge from '@/components/pde/PDEBadge.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEProgressBar from '@/components/pde/PDEProgressBar.vue'

export default {
  name: 'Reminder',

  components: {
    PDEBadge,
    PDEHeading,
    PDEIcon,
    PDEProgressBar,
  },

  props: {
    actionLabel: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: undefined,
    },
    steps: {
      type: Number,
      default: 0,
    },
  },

  emits: ['actionClick'],

  computed: {
    // Convert percentage to number of steps completed
    getPercentSteps() {
      return Math.floor(this.percentage / (100 / this.steps))
    },
    reminderIsComplete() {
      return this.percentage === 100
    },
  },
}
