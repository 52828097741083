
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEBadge from '@/components/pde/PDEBadge.vue'
import PDEReminder from '@/components/pde/PDEReminder.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'RemindersSection',
  components: {
    PDEHeading,
    PDEBadge,
    PDEReminder,
    PDEIcon,
  },
  data({ $router, $t }) {
    return {
      reminders: [
        {
          key: 'application-progress',
          title: 'Application progress',
          description: $t('account.dashboard.reminders.application'),
          percentage: 0,
          actionLabel: 'Go to application',
          actionHandler() {
            $router.push('/application')
          },
        },
        {
          key: 'application-start',
          title: 'Start your application',
          description: $t('account.dashboard.reminders.application'),
          actionLabel: 'Go to application',
          actionHandler() {
            $router.push('/application')
          },
        },
        {
          key: 'documents-needed',
          title: '2 Documents Needed',
          description: 'We still need a few documents from you.',
          actionLabel: 'Go to documents',
          actionHandler() {
            $router.push('/documents')
          },
        },
      ],
    }
  },
  computed: {
    hasReminders() {
      return this.reminders.length > 0
    },
  },
}

